import { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./index.css";
import { withTranslation } from "@template/components/translation";
import {
  trackPageInteraction,
  ANALYTICS_INTERACTION_CLICK,
  ANALYTICS_CONTEXT
} from "../../../helpers/eventing/events";

const cx = classnames.bind(styles);

class CountrySelectorButton extends Component {
  trackOpenCountrySelector = () => {
    const { position } = this.props;
    trackPageInteraction({
      context: ANALYTICS_CONTEXT.openCountryModal,
      interaction: ANALYTICS_INTERACTION_CLICK,
      properties: {
        positionOnPage: position
      }
    });
  };

  handleClick = () => {
    const { openCountrySelector, closeSidePanel } = this.props;
    closeSidePanel(); //@TODO: this should be handled in middleware
    openCountrySelector();
    this.trackOpenCountrySelector();
  };

  render() {
    const { formatTranslation, countryFlag, countryName, withLabels } =
      this.props;

    const containerClass = withLabels
      ? cx(styles.container, styles.withLabels)
      : styles.container;

    return (
      <div className={containerClass} data-testid="country-selector">
        {withLabels && (
          <h3 className={styles.header}>
            {formatTranslation("country_selector_button_heading")}
          </h3>
        )}
        <button
          className={styles.button}
          data-testid="country-selector-btn"
          type="button"
          onClick={this.handleClick}
          aria-label={`${formatTranslation(
            "country_selector_button_locationtext"
          )} ${countryName} ${formatTranslation(
            "country_selector_button_change_button"
          )}`}
        >
          {withLabels && (
            <span className={styles.locationText}>
              {formatTranslation("country_selector_button_locationtext")}
            </span>
          )}
          <img
            src={countryFlag}
            alt={countryName}
            className={styles.countryFlag}
          />
          {withLabels && (
            <div className={styles.changeLabel}>
              <span className={styles.pipe} aria-hidden="true" />
              <span>
                {formatTranslation("country_selector_button_change_button")}
              </span>
            </div>
          )}
        </button>
      </div>
    );
  }
}

CountrySelectorButton.defaultProps = {
  withLabels: false
};

CountrySelectorButton.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  countryFlag: PropTypes.string.isRequired,
  countryName: PropTypes.string.isRequired,
  openCountrySelector: PropTypes.func.isRequired,
  withLabels: PropTypes.bool,
  position: PropTypes.string.isRequired,
  closeSidePanel: PropTypes.func.isRequired
};

export default withTranslation(CountrySelectorButton);
