import { PureComponent } from "react";
import PropTypes from "prop-types";
import { stringify } from "query-string";
import withViewport from "../Viewport/withViewport";
import { withTranslation } from "@template/components/translation";
import styles from "./index.css";
import stylesDeferred from "./deferred.css";
import { getMyAccountBaseUrl } from "../../helpers/externalLinks";
import {
  MY_ACCOUNT,
  MY_ORDERS,
  MY_RETURNS,
  RETURNS_INFORMATION,
  MY_ACCOUNT_DEFAULT,
  CONTACT_PREFERENCES,
  MY_ACCOUNT_CHRISTMAS_LOGGEDIN,
  MY_ACCOUNT_CHRISTMAS_LOGGEDOUT,
  MY_ACCOUNT_EASTER_LOGGEDIN,
  MY_ACCOUNT_EASTER_LOGGEDOUT,
  MY_ACCOUNT_HALLOWEEN_LOGGEDIN,
  MY_ACCOUNT_HALLOWEEN_LOGGEDOUT,
  MY_ACCOUNT_STPATRICKS_LOGGEDIN,
  MY_ACCOUNT_STPATRICKS_LOGGEDOUT
} from "./constants";
import {
  CHRISTMAS,
  STPATRICKS,
  HALLOWEEN,
  EASTER
} from "@template/helpers/eventConstants";

class MyAccountLinks extends PureComponent {
  static propTypes = {
    countryCode: PropTypes.string.isRequired,
    defaultStoreUrl: PropTypes.string.isRequired,
    focusable: PropTypes.bool,
    formatTranslation: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool,
    keyStoreDataversion: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    myReturnsToggle: PropTypes.bool.isRequired,
    queryParams: PropTypes.object,
    storeCode: PropTypes.string.isRequired,
    currentEvent: PropTypes.string
  };

  buildLinks() {
    const {
      countryCode,
      defaultStoreUrl,
      formatTranslation,
      keyStoreDataversion,
      lang,
      myReturnsToggle,
      queryParams = {},
      storeCode
    } = this.props;

    const queryString =
      "?" +
      stringify({
        ...queryParams,
        lang,
        store: storeCode,
        country: countryCode,
        keyStoreDataversion
      });

    const baseUrl = getMyAccountBaseUrl();

    const myReturnsLink = myReturnsToggle
      ? [
          {
            id: MY_RETURNS,
            href: `${baseUrl}/returns${queryString}`,
            icon: stylesDeferred.MyReturns
          }
        ]
      : [];

    const links = [
      {
        id: MY_ACCOUNT,
        href: baseUrl + queryString,
        icon: this.getMyAccountIcon()
      },
      {
        id: MY_ORDERS,
        href: `${baseUrl}/orders${queryString}`,
        icon: stylesDeferred.MyOrders
      },
      ...myReturnsLink,
      {
        id: RETURNS_INFORMATION,
        href: defaultStoreUrl.concat(
          formatTranslation(
            `myaccountlinks_${RETURNS_INFORMATION.toLowerCase()}_link`
          ),
          "?",
          stringify(queryParams)
        ),
        icon: stylesDeferred.ReturnsInformation
      },
      {
        id: CONTACT_PREFERENCES,
        href: `${baseUrl}/contact-preferences${queryString}`,
        icon: stylesDeferred.ContactPreferences
      }
    ];

    return links;
  }

  getMyAccountIcon = () => {
    const { isLoggedIn, currentEvent } = this.props;

    const eventIconsAccount = [
      {
        event: CHRISTMAS,
        loggedIn: MY_ACCOUNT_CHRISTMAS_LOGGEDIN,
        loggedOut: MY_ACCOUNT_CHRISTMAS_LOGGEDOUT
      },
      {
        event: STPATRICKS,
        loggedIn: MY_ACCOUNT_STPATRICKS_LOGGEDIN,
        loggedOut: MY_ACCOUNT_STPATRICKS_LOGGEDOUT
      },
      {
        event: EASTER,
        loggedIn: MY_ACCOUNT_EASTER_LOGGEDIN,
        loggedOut: MY_ACCOUNT_EASTER_LOGGEDOUT
      },
      {
        event: HALLOWEEN,
        loggedIn: MY_ACCOUNT_HALLOWEEN_LOGGEDIN,
        loggedOut: MY_ACCOUNT_HALLOWEEN_LOGGEDOUT
      }
    ];

    const activeEvent = eventIconsAccount.find(
      eventIcon => eventIcon.event === currentEvent
    );

    if (!activeEvent) {
      const defaultIcon = MY_ACCOUNT_DEFAULT;

      return defaultIcon;
    }

    const eventIcon = isLoggedIn ? activeEvent.loggedIn : activeEvent.loggedOut;

    return eventIcon;
  };

  render() {
    const { formatTranslation, isVisible } = this.props;

    if (!isVisible) return null;

    return (
      <ul className={styles.container}>
        {this.buildLinks().map(({ id, href, icon }) => (
          <li key={id}>
            <a
              href={href}
              className={styles.link}
              data-testid={`${id.toLowerCase()}-link`}
            >
              <span className={icon} />
              {formatTranslation(`myaccountlinks_${id.toLowerCase()}`)}
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

export default withViewport(withTranslation(MyAccountLinks));
